




































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { Debounce } from "vue-debounce-decorator";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {},
})
export default class FormularioTrekking extends Mixins(FormValidator) {
  private win = window;
  private cerrado = false;
  private verCondiciones = false;
  private base = window.location;
  private customModel = "no";

  private dataForm: any = {
    telefono: "",
    email: "",
    nombre: "",
    rut: "",
    nombre_representante: "",
    rut_representante: "",
    direccion: "",
    fecha_nacimiento: "",
    documento: "",
    documento_representante: "",
    datos_medicos: "",
  };
  private formattedFecha = "";
  private cupoNinosAgotados = false;
  private cupoAdolescentesAgotados = false;

  private autorizacion: File | null = null;
  private invalidFile: boolean = true;

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  };

  @Watch("dataForm.rut")
  private formatRut(newValue: string) {
    newValue = newValue
      .replace(/\./g, "")
      .replace(/-/g, "")
      .toUpperCase();
    if (newValue.length > 1) {
      const rut = newValue.substring(0, newValue.length - 1);
      const dv = newValue.substring(newValue.length - 1);
      this.dataForm.rut = `${rut}-${dv}`;
    } else {
      this.dataForm.rut = newValue;
    }
  }

  @Watch("dataForm.rut_representante")
  private formatRutRepresentante(newValue: string) {
    newValue = newValue
      .replace(/\./g, "")
      .replace(/-/g, "")
      .toUpperCase();
    if (newValue.length > 1) {
      const rut = newValue.substring(0, newValue.length - 1);
      const dv = newValue.substring(newValue.length - 1);
      this.dataForm.rut_representante = `${rut}-${dv}`;
    } else {
      this.dataForm.rut_representante = newValue;
    }
  }

  @Watch("formattedFecha")
  @Debounce(1000)
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA).",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      this.dataForm.fecha_nacimiento = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fecha_nacimiento = new Date(birthDate);
    }
  }

  @Watch("dataForm.documento")
  private formatDocumento(newValue: string) {
    let valor = newValue.replace(/\D/g, "");
    valor = valor.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    this.dataForm.documento = valor;
  }

  @Watch("dataForm.documento_representante")
  private formatDocumentoRepresentante(newValue: string) {
    let valor = newValue.replace(/\D/g, "");
    valor = valor.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    this.dataForm.documento_representante = valor;
  }

  private mounted() {
    this.validarCupo();
    document.title =
      "Inscripción Trekking 2024 - Ilustre Municipalidad de Punta Arenas";
  }

  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.invalidFile = true;
        Notify.create({
          color: "negative",
          message: "El archivo excede el máximo (5mb).",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        this.autorizacion = null;
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFile = true;
      } else {
        this.autorizacion = target.files[0];
        this.invalidFile = false;
      }
    }
  }

  private validarCupo() {
    this.$axios
      .get("/formulario/trekking/cupos")
      .then((res: any) => {
        if (res.data === false) {
          // No hay cupos para ninguna categoría
          this.cupoAdolescentesAgotados = true;
          this.cupoNinosAgotados = true;
          this.cerrado = true;
          return;
        }

        // cupos disponibles  ambas
        if (res.data.message === "cupos") {
          this.cupoAdolescentesAgotados = false;
          this.cupoNinosAgotados = false;
          this.cerrado = false;
          return;
        }

        // solo hay cupos para niños
        if (res.data.message === "cupos_ninos") {
          this.cupoAdolescentesAgotados = true;
          this.cupoNinosAgotados = false;
          this.cerrado = false;
          return;
        }

        // solo hay cupos para adolescentes
        if (res.data.message === "cupos_adolescentes") {
          this.cupoNinosAgotados = true;
          this.cupoAdolescentesAgotados = false;
          this.cerrado = false;
          return;
        }

        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.$q.loading.hide();
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un error, por favor intente nuevamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
      });
  }

  private aceptarTerminos() {
    this.verCondiciones = false;
    this.customModel = "si";
  }
  private rechazarTerminos() {
    this.verCondiciones = false;
    this.customModel = "no";
  }

  private enviar() {
    const validRutMenor = this.validateRut(this.dataForm.rut);
    const validRutRepresentante = this.validateRut(
      this.dataForm.rut_representante
    );

    if (this.dataForm.nombre.trim() == "" || this.dataForm.nombre.length < 3) {
      let mensaje =
        "Por favor ingrese nombre válido del niño(a) o adolescente.";

      Notify.create({
        color: "negative",
        message: mensaje,
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataForm.rut == "" || !validRutMenor) {
      let mensaje = "Por favor ingrese RUT válido del niño(a) o adolescente.";
      Notify.create({
        color: "negative",
        message: mensaje,
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataForm.documento == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese N° documento del niño(a) o adolescente.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento == "" ||
      this.dataForm.fecha_nacimiento == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    // fechas 00:00:00
    const fechaLimite = new Date("2024-12-31T00:00:00");
    const fechaNacimiento = new Date(this.dataForm.fecha_nacimiento);

    // 00:00:00
    fechaNacimiento.setHours(0, 0, 0, 0);
    fechaLimite.setHours(0, 0, 0, 0);

    //edad límite (31 de diciembre de 2024)
    let edad = fechaLimite.getFullYear() - fechaNacimiento.getFullYear();
    const mes = fechaLimite.getMonth() - fechaNacimiento.getMonth();
    const dia = fechaLimite.getDate() - fechaNacimiento.getDate();

    if (mes < 0 || (mes === 0 && dia < 0)) {
      edad--;
    }

    if (edad < 6 || edad > 18) {
      Notify.create({
        color: "negative",
        message:
          "El niño(a) o adolescente debe tener entre 6 y 18 años cumplidos hasta el 31 de diciembre de 2024.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (edad >= 6 && edad <= 12 && this.cupoNinosAgotados) {
      Notify.create({
        color: "negative",
        message: "Cupos para niños y niñas de 6 a 12 años agotados.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (edad >= 13 && edad <= 18 && this.cupoAdolescentesAgotados) {
      Notify.create({
        color: "negative",
        message: "Cupos para adolescentes de 13 a 18 años agotados.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.dataForm.fecha_nacimiento.getFullYear() < 1890) {
          Notify.create({
            color: "negative",
            message:
              "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        }
      }
    }

    if (
      this.dataForm.direccion.trim() == "" ||
      this.dataForm.direccion.length < 3
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un domicilio.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (
      this.dataForm.nombre_representante.trim() == "" ||
      this.dataForm.nombre_representante.length < 3
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese nombre del Adulto responsable.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (
      this.dataForm.rut_representante == "" ||
      !validRutRepresentante
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese RUT válido del Adulto responsable.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataForm.rut_representante == this.dataForm.rut) {
      Notify.create({
        color: "negative",
        message:
          "El RUT del Adulto responsable debe ser distinto al RUT del niño(a) o adolescente.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataForm.documento_representante == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese N° documento del responsable.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataForm.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataForm.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataForm.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (!this.validEmail(this.dataForm.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.customModel == "no") {
      Notify.create({
        color: "negative",
        message:
          "Para continuar, debe leer y aceptar los Términos y Condiciones.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    // else if (this.autorizacion == null) {
    //   Notify.create({
    //     color: "negative",
    //     message: "Por favor, adjunte la autorización requerida (5mb max.).",
    //     icon: "report_problem",
    //     position: "top",
    //     timeout: 2500
    //   });
    //   return;
    // }
    this.$q.loading.show({
      delay: 100, // ms
    });

    const data = new FormData();
    // if (this.autorizacion) {
    //   const ciFile = this.autorizacion as Blob;
    //   data.append("autorizacion", ciFile);
    // } else {
    //   const ciFile = new File(["file"], "autorizacion") as Blob;
    //   data.append("autorizacion", ciFile);
    // }

    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }

    this.$axios
      .post("formulario/trekking", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res: any) => {
        this.$q.loading.hide();

        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente el formulario de inscripción.",
          icon: "report_problem",
          position: "top",
          timeout: 4000,
        });

        this.$q.loading.hide();

        this.routerGo("Home");
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        if (err.response.data.message == "REGISTRADO") {
          let mensaje = "El RUT ingresado ya cuenta con un registro.";
          Notify.create({
            color: "negative",
            message: mensaje,
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else {
          if (err.response.data.message == "CUPOS_NINOS") {
            Notify.create({
              color: "negative",
              message: "Cupos para niños de 6 a 12 años agotados.",
              icon: "report_problem",
              position: "top",
              timeout: 2500,
            });
            this.cupoNinosAgotados = true;

            return;
          } else if (err.response.data.message == "CUPOS_ADOLESCENTES") {
            Notify.create({
              color: "negative",
              message: "Cupos para adolescentes de 13 a 18 años agotados.",
              icon: "report_problem",
              position: "top",
              timeout: 2500,
            });
            this.cupoAdolescentesAgotados = true;
            return;
          } else if (err.response.data.message == "CUPOS_TOTALES_AGOTADOS") {
            Notify.create({
              color: "negative",
              message: "No hay cupos disponibles.",
              icon: "report_problem",
              position: "top",
              timeout: 2500,
            });
            this.cerrado = true;
            this.cupoNinosAgotados = true;
            this.cupoAdolescentesAgotados = true;
            return;
          } else {
            Notify.create({
              color: "negative",
              message: "Ha ocurrido un error, por favor intente nuevamente.",
              icon: "report_problem",
              position: "top",
              timeout: 2500,
            });
            return;
          }
        }
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }

  //   private irPagina(nombreWeb: any) {
  //     if (nombreWeb == "contacto") {
  //       this.routerGo("Contacto");
  //     } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
  //       this.routerGo("Concejo");
  //     } else if (
  //       nombreWeb == "actas-del-concejo" ||
  //       nombreWeb == "actas-del-concejo-municipal"
  //     ) {
  //       this.routerGo("ActasConcejo");
  //     } else if (
  //       nombreWeb == "oficinas-y-telefonos-municipales" ||
  //       nombreWeb == "contactos"
  //     ) {
  //       this.routerGo("Telefonos");
  //     } else if (
  //       nombreWeb == "organigrama-municipal" ||
  //       nombreWeb == "organigrama"
  //     ) {
  //       this.routerGo("Organigrama");
  //     } else if (nombreWeb == "cosoc") {
  //       this.routerGo("Cosoc");
  //     } else if (nombreWeb == "informes-financieros") {
  //       this.routerGo("Informes");
  //     } else if (nombreWeb == "ordenanzas-municipales") {
  //       this.routerGo("Ordenanzas");
  //     } else if (nombreWeb == "ofertas-laborales") {
  //       this.routerGo("OfertasOmil");
  //     } else {
  //       if (nombreWeb && nombreWeb != "") {
  //         if (
  //           this.$router.currentRoute.name == "Pagina" &&
  //           this.$router.currentRoute.params.nombre &&
  //           this.$router.currentRoute.params.nombre == nombreWeb
  //         ) {
  //           this.$router.go(0);
  //         } else {
  //           if (this.$router.currentRoute.name == "Pagina") {
  //             if (
  //               this.$router.currentRoute.params.nombre &&
  //               this.$router.currentRoute.params.nombre != nombreWeb
  //             ) {
  //               this.$router
  //                 .push({ name: "Pagina", params: { nombre: nombreWeb } })
  //                 .catch((err) => {
  //                   console.log(err);
  //                 });
  //               this.$router.go(0);
  //             } else {
  //               this.$router
  //                 .push({ name: "Pagina", params: { nombre: nombreWeb } })
  //                 .catch((err) => {
  //                   console.log(err);
  //                 });
  //             }
  //           } else {
  //             this.$router
  //               .push({ name: "Pagina", params: { nombre: nombreWeb } })
  //               .catch((err) => {
  //                 console.log(err);
  //               });
  //           }
  //         }
  //       }
  //     }
  //   }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  private checkFecha(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
